import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/Home'
import About from '@/views/about/About'
import Settled from '@/views/settled/Settled'
import App from '@/views/app/App'
import Privacy from '@/views/privacy/Privacy'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/home"
  },
  {
    path: '/home',
    meta: {
      title: '潜越首页',
      showNavbar: true,
      showBottomInfo: true
    },
    component: Home
  },
  {
    path: '/about',
    meta: {
      title: '关于我们',
      showNavbar: true,
      showBottomInfo: true
    },
    component: About
  },
  {
    path: '/settled',
    meta: {
      title: '入驻潜越',
      showNavbar: true,
      showBottomInfo: true
    },
    component: Settled
  },
  {
    path: '/app',
    meta: {
      title: '手机页面',
      showNavbar: true,
      showBottomInfo: true
    },
    component: App
  },
  {
    path: '/privacy',
    meta: {
      title: '隐私政策',
      showNavbar: false,
      showBottomInfo: false
    },
    component: Privacy
  }
]

const router = new VueRouter({
  routes
})

export default router