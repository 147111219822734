<template>
  <div class="con2">
    <div class="con_text">
      <div class="text1">细致作钓分类</div>
      <div class="text2">直柄、枪柄、远投、微物、打黑各类型装备分类细致有序，高效快速的购买到符合自己作钓需求的装备。</div>
    </div>
    <div><img v-lazy="require('@/assets/images/pc/home.png')" alt="appshop"></div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.con2 {
  width: 100%;
  height: 740px;
  background-color: #EFEFEF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.con2 img {
  height: 580px;
}

.con_text {
  margin-right: 60px;
}

.text1 {
  font-family: "Arial", sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: #8100A3;
}

.text2 {
  font-family: "Arial", sans-serif;
  font-size: 20px;
  color: #373737;
  margin-top: 35px;
}
</style>