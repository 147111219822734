import { render, staticRenderFns } from "./category.vue?vue&type=template&id=d1796852&scoped=true"
import script from "./category.vue?vue&type=script&lang=js"
export * from "./category.vue?vue&type=script&lang=js"
import style0 from "./category.vue?vue&type=style&index=0&id=d1796852&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1796852",
  null
  
)

export default component.exports