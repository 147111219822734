<template>
    <div>
        <navbar v-if="shouldShowNavbar" />
        <router-view />
        <bottom-info v-if="shouldShowBottomInfo" />
    </div>
</template>

<script>
import navbar from '@/components/navBar/NavBar';
import BottomInfo from '@/components/bottomInfo/BottomInfo';

export default {
    components: {
        navbar,
        BottomInfo
    },
    computed: {
        shouldShowNavbar() {
            return this.$route.meta.showNavbar !== false;
        },
        shouldShowBottomInfo() {
            return this.$route.meta.showBottomInfo !== false;
        }
    }
}
</script>