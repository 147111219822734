<template>
  <div class="con5">
    <div class="con5_text">
      <div class="text1">更多功能 敬请期待</div>
      <div class="text2" style="color: #9A9A9A;">查找钓鱼标点，装备AI智能搭配，装备排行榜，路亚教学知识库，装备维修，装备保养等各类服务....</div>
    </div>
    <div>
      <el-carousel :interval="2000" height="150px" indicator-position="none">
        <el-carousel-item v-for="adIndex in images" :key="adIndex">
          <img v-lazy="adIndex.url" alt="brand" class="brand_img1">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div>
      <el-carousel :interval="2000" height="150px" indicator-position="none">
        <el-carousel-item v-for="item in imgs" :key="item">
          <img v-lazy="item.path" alt="brand" class="brand_img2">
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        {
          url: require("@/assets/images/pc/brand1.png")
        },
        {
          url: require("@/assets/images/pc/brand2.png")
        },
        {
          url: require("@/assets/images/pc/brand3.png")
        },
      ],
      imgs: [
        {
          path: require("@/assets/images/pc/brand4.png")
        },
        {
          path: require("@/assets/images/pc/brand5.png")
        },
        {
          path: require("@/assets/images/pc/brand6.png")
        },
        {
          path: require("@/assets/images/pc/brand7.png")
        },
      ]
    }
  }
};
</script>

<style scoped>
.con5 {
  width: 100%;
  height: 740px;
  background-color: #fff;
}

.con5_text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 120px;
}

.text1 {
  font-family: "Arial", sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: #8100A3;
}

.text2 {
  font-family: "Arial", sans-serif;
  font-size: 20px;
  margin-top: 80px;
  margin-bottom: 150px;
}

.brand_img1 {
  position: fixed;
  width: 100%;
  height: 150px;
  display: block;
  z-index: -100;
}

.brand_img2 {
  position: fixed;
  width: 100%;
  height: 150px;
  display: block;
  z-index: -100;
}
</style>