<template>
    <div class="box" id="bottom">
        <div class="left"></div>
        <div class="mid">
            <div class="mid_icon">
                <div class="gz" style="margin-right: 45px;">
                    <img src="@/assets/images/pc/gzm.png" alt="gzm" class="gzm" v-show="gzActive"
                        @mouseleave="gzActive = false">
                    <img src="@/assets/images/pc/gzb.png" alt="gzb" class="gzb" v-show="gzActive"
                        @mouseleave="gzActive = false">
                    <img src="@/assets/images/pc/off.png" alt="off" style="margin-right: 45px;" v-show="!gzActive"
                        @mouseenter="gzActive = true" class="gzy">
                </div>
                <div class="gz" style="margin-right: 45px;">
                    <img src="@/assets/images/pc/wxm.png" alt="gzm" class="gzm" v-show="wxActive"
                        @mouseleave="wxActive = false">
                    <img src="@/assets/images/pc/wxb.png" alt="gzb" class="gzb" v-show="wxActive"
                        @mouseleave="wxActive = false">
                    <img src="@/assets/images/pc/wx.png" alt="off" style="margin-right: 45px;" v-show="!wxActive"
                        @mouseenter="wxActive = true" class="gzy">
                </div>
                <div class="gz">
                    <img src="@/assets/images/pc/dym.png" alt="gzm" class="gzm" v-show="dyActive"
                        @mouseleave="dyActive = false">
                    <img src="@/assets/images/pc/dyb.png" alt="gzb" class="gzb" v-show="dyActive"
                        @mouseleave="dyActive = false">
                    <img src="@/assets/images/pc/dy.png" alt="off" v-show="!dyActive" @mouseenter="dyActive = true"
                        class="gzy">
                </div>
            </div>
            <div class="mid_text_one">商家合作&nbsp&nbsp&nbsp|&nbsp&nbsp&nbsp融资对接&nbsp&nbsp&nbsp|&nbsp&nbsp&nbsp
                <router-link to="/privacy">隐私政策</router-link>
            </div>
            <div class="mid_text_two">版权所有：广州锐思云信息科技有限公司&nbsp&nbsp&nbsp&nbsp&nbsp|&nbsp&nbsp&nbsp
                <a href="http://beian.miit.gov.cn/" target="_blank">粤ICP备2023063105号-3</a>
            </div>
        </div>
        <div class="right"><img src="@/assets/images/pc/footer.png" alt="qylogo"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            gzActive: false,
            wxActive: false,
            dyActive: false
        };
    },
    name: 'YourComponent'
}
</script>

<style scoped>
.box {
    width: 100%;
    height: 355px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3A3A3A;
}

.left {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mid {
    width: 34%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.gz {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.gzm {
    width: 140px;
    margin-bottom: 10px;
}

.gzb {
    width: 40px;
}

.gzy {
    width: 30px;
}

.mid_icon {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
}

/* .mid_icon img {
    width: 30px;
} */

.mid_text_one {
    margin-bottom: 30px;
    font-family: "Arial", sans-serif;
    color: #fff;
    font-size: 18px;
}

.mid_text_one a {
    font-family: "Arial", sans-serif;
    color: #fff;
    font-size: 18px;
    text-decoration: none;
}

.mid_text_two {
    font-family: "Arial", sans-serif;
    color: #fff;
    font-size: 12px;
}

.mid_text_two a {
    font-family: "Arial", sans-serif;
    color: #fff;
    font-size: 12px;
    text-decoration: none;
}

.right {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.right img {
    width: 430px;
}
</style>