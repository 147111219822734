<template>
  <div class="outer">
    <div class="left_nav">
      <img src="@/assets/images/pc/qyLogo.png" alt="qylogo">
    </div>
    <div class="right_nav">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" text-color="#767676"
        active-text-color="#100000" router style="border-bottom: none;">
        <el-menu-item index="/home">潜越首页</el-menu-item>
        <el-menu-item index="/about">关于我们</el-menu-item>
        <el-menu-item index="/settled">入驻潜越</el-menu-item>
        <el-menu-item @click="scrollToFooter"><a href="#bottom">联系我们</a></el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        activeIndex: '/home'
      }
    },
    mounted() {
      this.activeIndex = this.$route.path
    },
    methods: {
      scrollToFooter() {
        const footer = document.getElementById('footer');
        footer.scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  }
</script>

<style scoped>
  .outer {
    width: 100%;
    height: 80px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .left_nav {
    width: 42%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .left_nav img {
    width: 73px;
    height: 35px;
  }

  .right_nav {
    width: 58%;
  }

  .el-menu-demo .el-menu-item,
  .el-menu-demo .el-menu-item a {
    font-family: "Arial", sans-serif;
    font-size: 15px;
    color: #767676;
  }

  .el-menu-demo .el-menu-item:hover,
  .el-menu-demo .el-menu-item a:hover {
    font-family: "Arial", sans-serif;
    font-size: 15px;
    font-weight: bold;
    color: #100000;
  }

  a {
    color: #767676;
    text-decoration: none;
  }

  a:hover {
    color: #100000;
  }
</style>