<template>
  <div class="con1">
    <div><img v-lazy="require('@/assets/images/pc/shop.png')" alt="appshop"></div>
    <div class="con_text">
      <div class="text1">正品商城</div>
      <div class="text2">正品保证，假一赔三，覆盖国内外90%的渔具品牌，货品齐全丰富，助力用户一站式购买！</div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.con1 {
  width: 100%;
  height: 740px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.con1 img {
  height: 580px;
}

.con_text {
  margin-left: 120px;
}

.text1 {
  font-family: "Arial", sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: #8100A3;
}

.text2 {
  font-family: "Arial", sans-serif;
  font-size: 20px;
  color: #373737;
  margin-top: 35px;
}
</style>