<template>
  <div class="outer">
    <div class="outbox">
      <div class="con1">
        路亚
      </div>
      <div class="inbox">
        <div class="con2">
          骑行&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp露营
        </div>
        <div class="mid_con">努力打造一个国内户外运动的聚集地</div>
        <div class="con3">
          滑雪&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp冲浪
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.outer {
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url('@/assets/images/pc/about.png');
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
}

.outbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.con1 {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 70px;
  font-family: "Arial", sans-serif;
}

.inbox {
  width: 67%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.con2 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 70px;
  font-family: "Arial", sans-serif;
  margin-bottom: 200px;
  margin-top: 246px;
}

.mid_con {
  color: #fff;
  font-weight: bold;
  font-size: 50px;
  font-style: italic;
  font-family: "Arial", sans-serif;
  margin-bottom: 200px;
}

.con3 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 70px;
  font-family: "Arial", sans-serif;
  margin-bottom: 246px;
}
</style>