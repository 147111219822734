<template>
    <div>
        <el-carousel :interval="4000" height="700px">
            <el-carousel-item v-for="item in imgList" :key="item.id">
                <img v-lazy="item.idView" alt="carousel" class="carousel_img">
                <div class="carousel_con">
                    <div><img v-lazy="require('@/assets/images/pc/app.png')" alt="applogo" style="width: 160px;"></div>
                    <div>来潜越&nbsp一起玩出精彩生活</div>
                    <div class="app_shop">
                        <div><img v-lazy="require('@/assets/images/pc/download.png')" alt="applogo" style="width: 160px;">
                        </div>
                        <div class="shop_icon">
                            <div class="android">
                                <a href="https://a.app.qq.com/o/simple.jsp?pkgname=com.ruisi.mall" target="_blank">
                                    <img v-lazy="require('@/assets/images/pc/android.png')" alt="androidshop">
                                </a>
                            </div>
                            <div>
                                <a href="https://apps.apple.com/app/id6462408062" target="_blank">
                                    <img v-lazy="require('@/assets/images/pc/ios.png')" alt="iosshop">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgList: [
                {
                    id: 0,
                    idView: require("@/assets/images/pc/carousel1.jpg")
                },
                {
                    id: 1,
                    idView: require("@/assets/images/pc/carousel7.jpg")
                },
                {
                    id: 2,
                    idView: require("@/assets/images/pc/carousel2.jpg")
                },
                {
                    id: 3,
                    idView: require("@/assets/images/pc/carousel3.jpg")
                },
                {
                    id: 4,
                    idView: require("@/assets/images/pc/carousel4.jpg")
                },
                {
                    id: 5,
                    idView: require("@/assets/images/pc/carousel5.jpg")
                },
                {
                    id: 6,
                    idView: require("@/assets/images/pc/carousel6.jpg")
                },
                {
                    id: 7,
                    idView: require("@/assets/images/pc/carousel8.jpg")
                }
            ]
        }
    }
}
</script>

<style scoped>
.carousel_img {
    position: fixed;
    width: 100%;
    height: 700px;
    display: block;
    z-index: -100;
}

.carousel_con {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    font-size: 75px;
    font-weight: bold;
    font-family: "Arial", sans-serif;
}

.app_shop {
    width: 320px;
    height: 140px;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.shop_icon {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.shop_icon img {
    width: 160px;
}

.android {
    margin-top: 20px;
}
</style>