<template>
  <div id="app">
    <div v-if="isSmallScreen">
      <app />
    </div>
    <div v-else>
      <layout>
        <router-view></router-view>
      </layout>
    </div>
  </div>
</template>

<script>
  import layout from '@/views/Layout.vue';
  import app from '@/views/app/App';
  export default {
    name: 'App',
    components: {
      layout,
      app
    },
    data() {
      return {
        isSmallScreen: false
      };
    },
    // created() {
    //   // console.log(this.$route.path, this.$route);
    //   if (this.$router.path !== "/home") this.$router.replace("/"); //刷新回到首页
    //   //replace中换成自己的首页路径，我只有/所以里边是空
    // },
    mounted() {
      this.checkScreenSize();
      window.addEventListener('resize', this.checkScreenSize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.checkScreenSize);
    },
    methods: {
      checkScreenSize() {
        this.isSmallScreen = window.innerWidth <= 768;
      }
    }
  };
</script>

<style>
  html,
  body {
    margin: 0;
    padding: 0;
  }

  #app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  router-view {
    flex-grow: 1;
    margin: 0;
    padding: 0;
  }
</style>