<template>
  <div class="outer">
    <div style="display: flex;">
      <div style="width: 30%;">
        <img src="@/assets/images/ruzhu/zixun1.png" alt="qylogo" class="qylogo" v-show="!zxWecath"
          @mouseenter="zxWecath = true">
        <img src="@/assets/images/ruzhu/zixun2.png" alt="qy" class="qylogo" v-show="zxWecath"
          @mouseleave="zxWecath = false">
      </div>
      <div>
        <img src="@/assets/images/ruzhu/wechat.png" alt="wechat" class="wechat" v-show="zxWecath"
          @mouseleave="zxWecath = false">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        zxWecath: false,
      };
    },
    name: 'settled'
  }
</script>

<style scoped>
  .outer {
    width: calc(100vw - 17px);
    height: 1980px;
    background-image: url('@/assets/images/ruzhu/bac.png');
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: 100% 100%;
  }

  .qylogo {
    width: 220px;
    height: 62px;
    margin-top: 74%;
    margin-left: 21.5%;
  }

  .wechat {
    width: 299px;
    height: 299px;
    margin-top: 90%;
    margin-left: 15%;
  }
</style>