<template>
    <div>
        <h1 style="text-align: center;">《潜越隐私政策》</h1>
        <br>

        <p>&nbsp&nbsp&nbsp&nbsp生效日期：2023年07月05日</p>
        <p>&nbsp&nbsp&nbsp&nbsp更新日期：2024年01月11日</p>
        <br>

        <p>&nbsp&nbsp【广州锐思云信息科技有限公司】（以下简称“我们”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原刻等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
        </p>
        <br>

        <p>&nbsp&nbsp&nbsp&nbsp请在使用我们的产品（或服务）前，仔细阅读并了解本《隐私政策》。</p>
        <h4>&nbsp&nbsp&nbsp&nbsp本政策将帮助您了解以下内容：</h4>
        <h4>&nbsp&nbsp&nbsp&nbsp1、我们如何收集和使用您的个人信息</h4>
        <h4>&nbsp&nbsp&nbsp&nbsp2、我们如何使用Cookle和同类技术</h4>
        <h4>&nbsp&nbsp&nbsp&nbsp3、我们如何共享、转让、公开披露您的个人信息</h4>
        <h4>&nbsp&nbsp&nbsp&nbsp4、我们如何保护您的个人信息</h4>
        <h4>&nbsp&nbsp&nbsp&nbsp5、您的权利</h4>
        <h4>&nbsp&nbsp&nbsp&nbsp6、我们如何处理儿童的个人信息</h4>
        <h4>&nbsp&nbsp&nbsp&nbsp7、您的个人信息如何在全球范围转移</h4>
        <h4>&nbsp&nbsp&nbsp&nbsp8、本隐私权政策如何更新</h4>
        <h4>&nbsp&nbsp&nbsp&nbsp9、如何联系我们</h4>
        <br>

        <h4>一、我们如何收集和使用您的个人信息</h4>
        <p>&nbsp&nbsp&nbsp&nbsp个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</p>
        <p>&nbsp&nbsp&nbsp&nbsp我们仅会出于本政策所述的以下目的，收集和使用您的个人信息：</p>
        <h4>&nbsp&nbsp&nbsp&nbsp（一）为您提供的服务</h4>
        <p>&nbsp&nbsp&nbsp&nbsp1、当您注册我们的服务时，<b
                style="text-decoration: underline;">我们会收集您的昵称、头像、手机号码，收集这些信息是为了帮助您完成注册，保护账号的安全。</b>手机号码属于敏感信息，收集此类信息是为了满足相关法律法规的网络实名制要求。您还可以根据自身需求选择填写性别、地区等信息。
        </p>
        <p>&nbsp&nbsp&nbsp&nbsp<b
                style="text-decoration: underline;">您提供的上述信息，将在您使用本服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除上述信息。</b></p>
        <p>&nbsp&nbsp&nbsp&nbsp上述信息将存储于中华人民共和国境内。如需跨境传输，我们将会单独征得您的授权同意。</p>
        <p>&nbsp&nbsp&nbsp&nbsp2、当您使用我们的服务时，为保障您正常使用我们的服务，维护我们服务的正常运行，改进及优化我们的服务体验以及保障您的账号安全，<b
                style="text-decoration: underline;">我们会根据您在访问及/或使用中的具体操作，接收并记录您所使用的设备相关信息（包括设备型号、设备名称、移动运营商、操作系统版本、ROM信息、设备设置、唯一设备标识符、硬件设备序列号、设备环境、移动应用列表等款硬件特征信息）、系统参数、设备所在位置相关信息（包括您授权的GPS地理位置以及WLAN接入点、WIFI状态和基站等传感器信息）设备唯一标识码（MAC/IMEI/Android
                ID/IDFA/IDFV/OPENUDID/G
                UID/SIM卡IMSI）、设备CPU/ABI型号。</b>服务日志信息：当您访问或使用潜越提供的产品或服务时，我们会自动收集和记录您的详细使用情况，作为服务日志保存，包括浏览、点击查看、搜索查询、收藏、交易、售后、关注分享信息、发布信息，以及IP地址、访问日期和时间。
        </p>
        <p>&nbsp&nbsp&nbsp&nbsp3、商品/服务的展示：为节省您搜索商品的时间。提升您的网购效率，我们会利用我们的技术向您展示更契合您需求的商品/服务。</p>
        <p>&nbsp&nbsp&nbsp&nbsp4、<b
                style="text-decoration: underline;">当您使用社区服务时，您上传的照片、评论、点赞、收藏等信息会存储在我们的服务器中，因为存储是实现这一功能所必需的。我们会以加密的方式存储，您也可以随时删除这些信息。</b>除非经您自主选择或遵从相关法律法规要求，我们不会对外提供上述社区发布的信息，或者将其用于该功能以外的其他用途。
        </p>
        <p>&nbsp&nbsp&nbsp&nbsp5、<b
                style="text-decoration: underline;">当您使用本地、附近的社区等功能时，我们会获得您的归属地区记录您的地理位置信息，目的是为了向您提供该服务。</b>该信息属于敏感信息，拒绝提供该信息仅会使您无法使用上述功能，但不影响您正常使用其他功能。此外，您也可以随时关闭相关功能并在该功能内清除您归属地信息。
        </p>
        <p>&nbsp&nbsp&nbsp&nbsp6、<b
                style="text-decoration: underline;">我们的服务可能包括或链接至第三方提供的社交媒体或其他服务（包括网站）。</b>例如：您可利用第三方连线服务登录我们的服务，或您可利用“分享”键将某些内容分享到第三方的服务。这些功能可能会收集您的信息（包括您的日志信息），从而正常运行上述功能。
        </p>
        <p>&nbsp&nbsp&nbsp&nbsp7、为了实现分享功能，我们使用了微信提供的社会化分享SDK，此产品的隐私策略条款，可以参考：https://weixin.qq.com/cgi-bin/readtemplate?t=weixin_agreement&s=privacy&cc=CN
        </p>
        <p>&nbsp&nbsp&nbsp&nbsp8、<b
                style="text-decoration: underline;">当您自行图片文字等内容时，我们会向您请求授权相机、照片、权限和设备所在位置信息权限（位置信息属于敏感信息）。</b>在此过程中，您可以自行选择是否向我们提供，您如果拒绝授权提供，将无法使用此附加功能，但不影响您正常使用潜越的其他功能。
        </p>
        <p>&nbsp&nbsp&nbsp&nbsp9、自启动和关联启动：当您在打开推送服务但无法正确接收到消息的情况下，我们可能会根据您软件的运行情况，偶发进行唤醒应用以便在后台进行消息的接收，以保证您能接收到推送的消息。</p>
        <p>&nbsp&nbsp&nbsp&nbsp请注意，出于故障诊断及内容推荐的功能实现，用户端及集成的第三方SDK会多次获取您上述的信息（如Androld
            ID，IP，）MSI、MAC地址等）。根据您打开页面的数量，频率在3到10次/分钟左右。</p>
        <h4>&nbsp&nbsp&nbsp&nbsp（二）开展内部数据分析和研究，第三方SDK统计服务，改善我们的产品或服务</h4>
        <p>&nbsp&nbsp&nbsp&nbsp我们收集数据是根据您与我们的互动和您所做出的选择，包括您的隐私设置以及您使用的产品和功能。我们收集的数据可能包括SDK/API/JS代码版本、浏览器、互联网服务提供商、IP地址、平台、时间戳、应用标识符、应用程序版本、应用分发渠道、独立设备标识符、IOS广告标识符（IDFA）、安卓广告主标识符、网卡（MAC）地址、移动应用列表、国际移动设备识别码（IMEI），SIM卡IMSI、设备型号、终端制造厂商、终端设备操作系统版本、会话启动/停止时间、语言所在地、时区和网络状态（WiFi等）、硬盘、CPU和电池使用情况等。
        </p>
        <h4>&nbsp&nbsp&nbsp&nbsp（三）根据相关法律法规及国家标准，以下情形中，我们可能会收集、使用您的相关个人信息无需征求您的授权同意。</h4>
        <p>&nbsp&nbsp&nbsp&nbsp1）与国家安全、国防安全等国家利益直接相关的；与公共安全、公共卫生、公众知情等重大公共利益直接相关的；</p>
        <p>&nbsp&nbsp&nbsp&nbsp2）与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
        <p>&nbsp&nbsp&nbsp&nbsp3）出于维护您或其他个人的生命、财产、声誉等重大合法权益但又很难得到本人同意的；</p>
        <p>&nbsp&nbsp&nbsp&nbsp4）所收集的个人信息是您自行向社会公众公开的；</p>
        <p>&nbsp&nbsp&nbsp&nbsp5）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道：</p>
        <p>&nbsp&nbsp&nbsp&nbsp6）根据您要求签订和履行合同所必需的；</p>
        <p>&nbsp&nbsp&nbsp&nbsp7）用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
        <p>&nbsp&nbsp&nbsp&nbsp8）为开展合法的新闻报道所必需的；</p>
        <p>&nbsp&nbsp&nbsp&nbsp9）出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
        <p>&nbsp&nbsp&nbsp&nbsp10）法律法规规定的其他情形。</p>

        <p>&nbsp&nbsp&nbsp&nbsp请您理解，我们向您提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明中且收集了您的信息，我们会通过页面提示、交互流程、网站公告等方式另行向您说明信息收集的内容、范围和目的，以征得您的同意。
        </p>
        <p>&nbsp&nbsp&nbsp&nbsp11）设备权限调用</p>
        <h4>&nbsp&nbsp&nbsp&nbsp<b
                style="text-decoration: underline;">为确保相关业务功能的正常实现，我们需要根据具体的使用场量调用对应的必要权限，并在调用前向您弹窗询问，具体的权限通用说明请见下表：</b>
        </h4>
        <h4>&nbsp&nbsp&nbsp&nbsp1.IOS权限调用说明设备权限</h4>
        <table style="border: 1px solid black;border-collapse: collapse;margin-left: 22%">
            <tr>
                <th style="border: 1px solid black;background-color: #d8d8d8">设备权限</th>
                <th style="border: 1px solid black;background-color: #d8d8d8">使用目的</th>
                <th style="border: 1px solid black;background-color: #d8d8d8">使用方式</th>
                <th style="border: 1px solid black;background-color: #d8d8d8">是否可关闭</th>
            </tr>
            <tr>
                <td style="border: 1px solid black;text-align: center">相机</td>
                <td style="border: 1px solid black;text-align: center">用于拍照、上传图片</td>
                <td style="border: 1px solid black;text-align: center">用户主动拍照、上传图片时询问</td>
                <td style="border: 1px solid black;text-align: center">是</td>
            </tr>
            <tr>
                <td style="border: 1px solid black;text-align: center">存储</td>
                <td style="border: 1px solid black;text-align: center">用于读取和写入用户拍摄的照片，应用内升级和数据缓存等</td>
                <td style="border: 1px solid black;text-align: center">首次打开用户端弹窗询问</td>
                <td style="border: 1px solid black;text-align: center">是</td>
            </tr>
            <tr>
                <td style="border: 1px solid black;text-align: center">设备信息</td>
                <td style="border: 1px solid black;text-align: center">用于保障用户账号安全</td>
                <td style="border: 1px solid black;text-align: center">首次打开用户端弹窗询问</td>
                <td style="border: 1px solid black;text-align: center">是</td>
            </tr>
        </table>
        <h4>&nbsp&nbsp&nbsp&nbsp2.Android权限调用说明设备权限</h4>
        <table style="border: 1px solid black;border-collapse: collapse;margin-left: 22%">
            <tr>
                <th style="border: 1px solid black;background-color: #d8d8d8">设备权限</th>
                <th style="border: 1px solid black;background-color: #d8d8d8">使用目的</th>
                <th style="border: 1px solid black;background-color: #d8d8d8">使用方式</th>
                <th style="border: 1px solid black;background-color: #d8d8d8">是否可关闭</th>
            </tr>
            <tr>
                <td style="border: 1px solid black;text-align: center">相机</td>
                <td style="border: 1px solid black;text-align: center">用于拍照、上传图片</td>
                <td style="border: 1px solid black;text-align: center">用户主动拍照、上传图片时询问</td>
                <td style="border: 1px solid black;text-align: center">是</td>
            </tr>
            <tr>
                <td style="border: 1px solid black;text-align: center">存储</td>
                <td style="border: 1px solid black;text-align: center">用于读取和写入用户拍摄的照片，应用内升级和数据缓存等</td>
                <td style="border: 1px solid black;text-align: center">首次打开用户端弹窗询问</td>
                <td style="border: 1px solid black;text-align: center">是</td>
            </tr>
            <tr>
                <td style="border: 1px solid black;text-align: center">设备信息</td>
                <td style="border: 1px solid black;text-align: center">用于保障用户账号安全</td>
                <td style="border: 1px solid black;text-align: center">首次打开用户端弹窗询问</td>
                <td style="border: 1px solid black;text-align: center">是</td>
            </tr>
        </table>
        <br>

        <h4>二、我们如何使用Cookie和同类技术</h4>
        <h4>&nbsp&nbsp&nbsp&nbsp（一）Cookle</h4>
        <p>&nbsp&nbsp&nbsp&nbsp为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookle
            通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好、历史浏览或收藏内的商品等数据。</p>
        <p>&nbsp&nbsp&nbsp&nbsp我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookle。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止
            Cookie的功能，但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问您所使用的浏览器相关设置页面。</p>
        <p>&nbsp&nbsp&nbsp&nbsp（二）网站信标和像素标签</p>
        <p>&nbsp&nbsp&nbsp&nbsp除Cookie外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像，借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
        </p>
        <p>&nbsp&nbsp&nbsp&nbsp（三）Do Not Track（请勿追踪）</p>
        <p>&nbsp&nbsp&nbsp&nbsp很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do Not Track
            请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do Not
            Track，那么我们的所有网站都会尊重您的选择。</p>
        <br>

        <h4>三、我们如何共享、转让、公开披露您的个人信息</h4>
        <h4>&nbsp&nbsp&nbsp&nbsp（一）共享</h4>
        <p>我们不会向其他任何公司、组织和个人分享您的个人信息，但以下情况除外：</p>
        <p>&nbsp&nbsp&nbsp&nbsp1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</p>
        <p>&nbsp&nbsp&nbsp&nbsp2、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。</p>
        <p>&nbsp&nbsp&nbsp&nbsp3、与授权合作伙伴共享：仅为实现本隐私权政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验，例如，我们聘请来提供第三方数据统计和分析服务的公司可能需要采集和访问个人数据以进行数据统计和分析。在这种情况下，这些公司必须遵守我们的数据隐私和安全要求。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。
        </p>
        <p>&nbsp&nbsp&nbsp&nbsp我们会通过应用程序接口（API）、软件工具开发包（SDK）与第三方进行必要的数据共享，不同版本的第三方SDK会有所不同，一般包括分享类、厂商推送类、统计类等，我们会对第三方进行严格的安全检测并约定数据保护措施。
        </p>
        <p>&nbsp&nbsp&nbsp&nbsp为保障潜越APP相关功能的实现与应用的稳定运行。我们可能会接入由第三方提供的软件开发包（SDK）以实现相关目的。不同版本的第三方SDK会有所不同，一般包括推送通知消息类、厂商推送类、分享类、统计类等。我们会对合作方获取信息的软件工具包（SDK）进行严格的安全监测，以保障数据安全。您可以通过以下链接查看第三方数据使用和保护规则。
        </p>
        <br>

        <h4>&nbsp&nbsp&nbsp&nbsp中国移动号码认证SDK</h4>
        <p>&nbsp&nbsp&nbsp&nbsp运营主体：中国移动通信集团有限公司</p>
        <p>&nbsp&nbsp&nbsp&nbsp使用场景/目的：识别用户的手机号码快速登录</p>
        <p>&nbsp&nbsp&nbsp&nbsp收集个人信息类型：当前手机号码的掩码、设备信息（设备识别码IMSIUMEI/AndroiID/MAC地址、SIM卡信息、应 用列表）</p>
        <p>&nbsp&nbsp&nbsp&nbsp隐私权政策链接：<a href="https://he.10086.cn/protocol/pc/PersonalInfoProtectionPolicy.html"
                target="_blank">https://he.10086.cn/protocol/pc/PersonalInfoProtectionPolicy.html</a>
        </p>
        <br>
        <h4>&nbsp&nbsp&nbsp&nbsp中国联通号码认证SDK</h4>
        <p>&nbsp&nbsp&nbsp&nbsp运营主体：中国联合网络通信集团有限公司</p>
        <p>&nbsp&nbsp&nbsp&nbsp使用场景/目的：识别用户的手机号码快速登录</p>
        <p>&nbsp&nbsp&nbsp&nbsp收集个人信息类型：设备标识信息、电话号码</p>
        <p>&nbsp&nbsp&nbsp&nbsp隐私权政策链接：<a href="https://img.client.10010.com/stprototype/stAPPxy/indexUser.html"
                target="_blank">https://img.client.10010.com/stprototype/stAPPxy/indexUser.html</a>
        </p>
        <br>
        <h4>&nbsp&nbsp&nbsp&nbsp中国电信号码认证SDK</h4>
        <p>&nbsp&nbsp&nbsp&nbsp运营主体：中国电信集团有限公司</p>
        <p>&nbsp&nbsp&nbsp&nbsp使用场景/目的：识别用户的手机号码快速登录</p>
        <p>&nbsp&nbsp&nbsp&nbsp收集个人信息类型：设备标识信息、电话号码、网络状态</p>
        <p>&nbsp&nbsp&nbsp&nbsp隐私权政策链接：<a
                href="https://e.189.cn/sdk/agreement/content.do?type=main&appKey=&hidetop=true&returnUrl="
                target="_blank">https://e.189.cn/sdk/agreement/content.do?type=main&appKey=&hidetop=true&returnUrl=</a>
        </p>
        <br>
        <h4>&nbsp&nbsp&nbsp&nbsp极光推送SDK</h4>
        <p>&nbsp&nbsp&nbsp&nbsp运营主体：深圳市和讯华谷信息技术有限公司</p>
        <p>&nbsp&nbsp&nbsp&nbsp使用场景/目的：Android混合推送SDK，快速集成6个厂商推送，共享系统推送通道，杀死也能收到推送，推送到达率90％以上</p>
        <p>&nbsp&nbsp&nbsp&nbsp收集个人信息类型：获取运行中进程信息</p>
        <p>&nbsp&nbsp&nbsp&nbsp隐私权政策链接：<a href="https://www.jiguang.cn/license/privacy"
                target="_blank">https://www.jiguang.cn/license/privacy</a>
        </p>
        <br>
        <h4>&nbsp&nbsp&nbsp&nbsp微信openSDK</h4>
        <p>&nbsp&nbsp&nbsp&nbsp运营主体：腾讯科技（深圳）有限公司</p>
        <p>&nbsp&nbsp&nbsp&nbsp使用场景/目的：提供微信APP分享服务</p>
        <p>&nbsp&nbsp&nbsp&nbsp收集个人信息类型：设备MAC地址、唯一设备标识码、位置信息、应用列表</p>
        <p>&nbsp&nbsp&nbsp&nbsp隐私权政策链接：<a href="https://open.weixin.qq.com/cgi-bin/frame?t=news/protocol_developer_tmpl"
                target="_blank">https://open.weixin.qq.com/cgi-bin/frame?t=news/protocol_developer_tmpl</a>
        </p>
        <br>
        <h4>&nbsp&nbsp&nbsp&nbsp阿里百川SDK、阿里百川电商、百川电商SDK</h4>
        <p>&nbsp&nbsp&nbsp&nbspSDK包名：com.alibaba.baichuan.trade.common、com.alibaba.baichuan.android、com.alibaba.baichuan.trade.biz、com.alibaba.mtl.appmonitor、mtopsdk。com.alibaba.alibclinkpartner、com.ali.auth、com.huawei.hms、com.tencent.smtt、com.huawei.agconnect、com.tencent.bugly、com.xiaomi.push、cn.jpush.android、、com.qq.e.comm、com.bytedance.sdk.openadsdk、com.tencent
            、com.googleandroid.gms.ads(AdMob) comxiaomipush eex,com.alipay.mobile,com.alipay.android,com.mpaas.uniapp</p>
        <p>&nbsp&nbsp&nbsp&nbspSDK收集：浙江淘宝网络有限公司、淘宝（中国）软件有限公司、北京阿里巴巴云计算技术有限公司</p>
        <p>&nbsp&nbsp&nbsp&nbsp数据的类型
            ：设备型号、操作系统版本、设备设置、MAC地址及IMEI、IDFA、OAID等设备标识符、设备环境、移动应用列表等软硬件特征信息）、设备所在位置相关信息（包括您授权的GPS位置以及WLAN接入点、蓝牙和基站等传感器信息）。阿里百川开放淘系电商能力，帮助APP开发者在各种场景下快速、低成本搭建无线电商导购业务，开发用户消费需求，实现商业变现。
        </p>
        <p>&nbsp&nbsp&nbsp&nbspSDK用途:唤起淘宝指定购物页面</p>
        <p>&nbsp&nbsp&nbsp&nbsp隐私权政策链接：<a href="https://baichuan.taobao.com/xieyi.htm?spm=0.0.0.0"
                target="_blank">https://baichuan.taobao.com/xieyi.htm?spm=0.0.0.0</a>
        </p>
        <br>
        <h4>&nbsp&nbsp&nbsp&nbsp阿里一键登录SDK（com.mobile.auth）</h4>
        <p>&nbsp&nbsp&nbsp&nbsp运营主体：阿里云计算有限公司</p>
        <p>&nbsp&nbsp&nbsp&nbsp使用场景/目的：用于提供手机号码一键登录服务。采集地理位置甄别分享通道，提供反作弊服务。</p>
        <p>&nbsp&nbsp&nbsp&nbsp个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息
        </p>
        <p>&nbsp&nbsp&nbsp&nbsp收集个人信息类型：采集设备标识符(IMEI/Mac/androidID/IDFA/OPENUDID/GUID、SIM卡IMSI信息)以及手机号码</p>
        <p>&nbsp&nbsp&nbsp&nbsp隐私权政策链接：<a
                href="https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html"
                target="_blank">https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html</a>
        </p>
        <br>
        <h4>&nbsp&nbsp&nbsp&nbsp华为SDK</h4>
        <p>&nbsp&nbsp&nbsp&nbsp运营主体：华为软件技术有限公司</p>
        <p>&nbsp&nbsp&nbsp&nbsp使用场景/目的：向华为手机用户推送消息</p>
        <p>&nbsp&nbsp&nbsp&nbsp收集个人信息类型：设备信息（设备标识码IMEI AndroidlD、BSSID/SSID、MCC/MNC、WIFI信息、设备序列码）、
            当前运行应用进程及包名、完全的网络访问权限（包括在后台状态下收集））、应用自启动</p>
        <p>&nbsp&nbsp&nbsp&nbsp隐私权政策链接：<a href="https://consumer.huawei.com/cn/privacy/privacy-policy/"
                target="_blank">https://consumer.huawei.com/cn/privacy/privacy-policy/</a>
        </p>
        <br>
        <h4>&nbsp&nbsp&nbsp&nbsp小米推送SDK</h4>
        <p>&nbsp&nbsp&nbsp&nbsp运营主体：小米移动软件有限公司</p>
        <p>&nbsp&nbsp&nbsp&nbsp使用场景/目的：向小米手机用户推送消息</p>
        <p>&nbsp&nbsp&nbsp&nbsp收集个人信息类型：设备信息（设备标识IMEI/MEID/Android ID/IMSI/OAID、BSSID/SSID、设备序列号）、外部存
            储、已安装应用列表及包名、当前运行应用进程及包名、应用自启动</p>
        <p>&nbsp&nbsp&nbsp&nbsp隐私权政策链接：<a href="https://privacy.mi.com/all/zh_CN/"
                target="_blank">https://privacy.mi.com/all/zh_CN/</a>
        </p>
        <br>
        <h4>&nbsp&nbsp&nbsp&nbspOPPO推送SDK</h4>
        <p>&nbsp&nbsp&nbsp&nbsp运营主体：广东欢太科技有限公司</p>
        <p>&nbsp&nbsp&nbsp&nbsp使用场景/目的：向OPPO手机用户推送消息</p>
        <p>&nbsp&nbsp&nbsp&nbsp收集个人信息类型：MEI号，Serial Number，IMSI，User 1 D，Androld ID，Google Advertising ID，手机Region设置，
            设备型号，手机电量，手机操作系统版本及语言、读取手机状态和身份、查看网络状态、完全的网络访问权限（包括在后台状态下收集）、应用自启动</p>
        <p>&nbsp&nbsp&nbsp&nbsp隐私权政策链接：<a href="https://muc.oppomobile.com/document/500/privacy_policy_zh-CN.html"
                target="_blank">https://muc.oppomobile.com/document/500/privacy_policy_zh-CN.html</a>
        </p>
        <br>
        <h4>&nbsp&nbsp&nbsp&nbsp腾讯Bugly SDK</h4>
        <p>&nbsp&nbsp&nbsp&nbsp运营主体：腾讯公司</p>
        <p>&nbsp&nbsp&nbsp&nbsp使用场景/目的：APP崩溃信息收集</p>
        <p>&nbsp&nbsp&nbsp&nbsp收集个人信息类型：ROM/RAM/SD卡容量、网络/语言状态、设备标识信息（IMEI设备识别码、androld＿id、lcci
            d），设备信息（设备类型、系统版本、设备名称、设备型号、设备CPU/ABI类型）（包括在后台状态下收集）</p>
        <p>&nbsp&nbsp&nbsp&nbsp隐私权政策链接：<a href="https://privacy.qq.com/policy/tencent-privacypolicy"
                target="_blank">https://privacy.qq.com/policy/tencent-privacypolicy</a>
        </p>
        <br>

        <p>&nbsp&nbsp&nbsp&nbsp请您知悉，我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，对我们仅为实现本政策中声明的目的与之共享个人信息的公司、组织和个人，我们会与其签署严格的信息保护和保密协定，要求他们遵守协议并采取相关的安全措施来保护您的个人信息。
        </p>
        <h4>&nbsp&nbsp&nbsp&nbsp（二）转让</h4>
        <p>&nbsp&nbsp&nbsp&nbsp我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
        <p>&nbsp&nbsp&nbsp&nbsp1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
        <p>&nbsp&nbsp&nbsp&nbsp2、在涉及合并，收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司，组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</p>
        <h4>&nbsp&nbsp&nbsp&nbsp（三）公开披露</h4>
        <p>&nbsp&nbsp&nbsp&nbsp我们仅会在以下情况下，公开披露您的个人信息：</p>
        <p>&nbsp&nbsp&nbsp&nbsp1、获得您明确同意后；</p>
        <p>&nbsp&nbsp&nbsp&nbsp2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</p>
        <br>

        <h4>四、我们如何保护您的个人信息</h4>
        <p>&nbsp&nbsp&nbsp&nbsp（一）我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与“服务＊之间交换数据（如信用卡信息）时受SSL加密保护；我们同时对我们网站提供
            https安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到思意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
        </p>
        <p>&nbsp&nbsp&nbsp&nbsp（二）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</p>
        <p>&nbsp&nbsp&nbsp&nbsp（三）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他我们用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。</p>
        <p>&nbsp&nbsp&nbsp&nbsp（四）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
        </p>
        <p>&nbsp&nbsp&nbsp&nbsp（五）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响。我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
            同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
        <br>

        <h4>五、您的权利</h4>
        <p>&nbsp&nbsp&nbsp&nbsp按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</p>
        <h4>&nbsp&nbsp&nbsp&nbsp（一）访问您的个人信息</h4>
        <p>&nbsp&nbsp&nbsp&nbsp您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：</p>
        <p>&nbsp&nbsp&nbsp&nbsp<b>账户信息</b>——如果您希望访问或编辑您的账户中的个人资料信息、更改您的密码、添加安全信息或关闭您的账户等，您可以进入用户端应用程序点击“我的”一点击头像和系统设置一个人资料查看账户信息。
        </p>
        <p>&nbsp&nbsp&nbsp&nbsp<b>搜索信息</b>——您可以在用户端应用程序的首页搜索、商城搜索中访问或清除您的搜索历史记录、查看和修改兴题以及管理其他数据。</p>
        <p>&nbsp&nbsp&nbsp&nbsp<b>社区信息</b>——您可在用户端应用程序底部导航栏点击“我的”，个人中心，查看个人的帖子、关注的好友、粉丝、我的收藏、点赞数、个性签名等信息进行管理。</p>
        <p>&nbsp&nbsp&nbsp&nbsp<b>商城信息</b>——您可在用户端应用程序底部导航栏点击“我的”，查看我的收藏、我的历史浏览、我的订单等信息进行管理。</p>
        <h4>&nbsp&nbsp&nbsp&nbsp<b
                style="text-decoration: underline;">如果您无法通过上述链接访问这些个人信息，发送电子邮件至race024@163.com。我们将在30天内回复您的访问请求。</b>
        </h4>
        <p>&nbsp&nbsp&nbsp&nbsp对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，请发送电子邮件至race024@163.com。</p>
        <h4>&nbsp&nbsp&nbsp&nbsp（二）更正您的个人信息</h4>
        <p>&nbsp&nbsp&nbsp&nbsp当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以通过”（一）访问您的个人信息”中罗列的方式提出更正申请。</p>
        <h4>&nbsp&nbsp&nbsp&nbsp<b
                style="text-decoration: underline;">如果您无法通过上述链接访问这些个人信息，发送电子邮件至race024@163.com。我们将在30天内回复您的访问请求。</b>
        </h4>
        <h4>&nbsp&nbsp&nbsp&nbsp（三）删除您的个人信息</h4>
        <p>&nbsp&nbsp&nbsp&nbsp在以下情形中，您可以向我们提出删除个人信息的请求：</p>
        <h4>&nbsp&nbsp&nbsp&nbsp<b style="text-decoration: underline;">1、如果我们处理个人信息的行为违反法律法规：</b></h4>
        <h4>&nbsp&nbsp&nbsp&nbsp<b style="text-decoration: underline;">2、如果我们收集、使用您的个人信息，却未征得您的同意：</b></h4>
        <h4>&nbsp&nbsp&nbsp&nbsp<b style="text-decoration: underline;">3、如果我们处理个人信息的行为违反了与您的约定：</b></h4>
        <h4>&nbsp&nbsp&nbsp&nbsp<b style="text-decoration: underline;">4、如果您不再使用我们的产品或服务，或您注销了账号：</b></h4>
        <h4>&nbsp&nbsp&nbsp&nbsp<b style="text-decoration: underline;">5、如果我们不再为您提供产品或服务。</b></h4>
        <p>&nbsp&nbsp&nbsp&nbsp若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。</p>
        <p>&nbsp&nbsp&nbsp&nbsp当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。</p>
        <h4>&nbsp&nbsp&nbsp&nbsp（四）个人信息主体注销账户</h4>
        <p>&nbsp&nbsp&nbsp&nbsp您随时可注销此前注册的账户，您可以通过以下方式自行操作：</p>
        <p>&nbsp&nbsp&nbsp&nbsp进入用户端应用程序——点击“我的—系统设置—注销账户”，进行账号注销。</p>
        <p>&nbsp&nbsp&nbsp&nbsp在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。</p>
        <h4>&nbsp&nbsp&nbsp&nbsp（五）个人信息主体获取个人信息副本</h4>
        <p>&nbsp&nbsp&nbsp&nbsp您有权获取您的个人信息副本，您可以通过以下方式自行操作：</p>
        <p>&nbsp&nbsp&nbsp&nbsp进入用户端应用程序—点击“我的”—系统设置—意见反馈，联系客服获取个人信息副本。</p>
        <h4>&nbsp&nbsp&nbsp&nbsp（六）约束信息系统自动决策</h4>
        <p>&nbsp&nbsp&nbsp&nbsp在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。</p>
        <h4>&nbsp&nbsp&nbsp&nbsp（七）响应您的上述请求</h4>
        <p>&nbsp&nbsp&nbsp&nbsp为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</p>
        <h4>&nbsp&nbsp&nbsp&nbsp<b style="text-decoration: underline;">我们将在三十天内做出答复。如您不满意，还可以通过以下途径投诉：</b></h4>
        <h4>&nbsp&nbsp&nbsp&nbsp<b style="text-decoration: underline;">发邮件至race024@163.com。</b></h4>
        <p>&nbsp&nbsp&nbsp&nbsp对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
        </p>
        <p>&nbsp&nbsp&nbsp&nbsp在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
        <p>&nbsp&nbsp&nbsp&nbsp1、与国家安全、国防安全有关的；</p>
        <p>&nbsp&nbsp&nbsp&nbsp2、与公共安全、公共卫生、重大公共利益有关的；</p>
        <p>&nbsp&nbsp&nbsp&nbsp3、与犯罪侦查、起诉和审判等有关的；</p>
        <p>&nbsp&nbsp&nbsp&nbsp4、有充分证据表明您存在主观恶意或滥用权利的；</p>
        <p>&nbsp&nbsp&nbsp&nbsp5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</p>
        <br>

        <h4>六、我们如何处理儿童的个人信息</h4>
        <h4>&nbsp&nbsp&nbsp&nbsp<b style="text-decoration: underline;">我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童不得创建自己的用户账户。</b>
        </h4>
        <h4>&nbsp&nbsp&nbsp&nbsp<b
                style="text-decoration: underline;">对于经父母同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。</b>
        </h4>
        <h4>&nbsp&nbsp&nbsp&nbsp<b style="text-decoration: underline;">尽管当地法律和习俗对儿童的定义不同，但我们将不满14周岁的任何人均视为儿童。</b></h4>
        <h4>&nbsp&nbsp&nbsp&nbsp<b
                style="text-decoration: underline;">如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。</b></h4>
        <br>

        <h4>七、您的个人信息如何在全球范围转移</h4>
        <p>&nbsp&nbsp&nbsp&nbsp原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。</p>
        <p>&nbsp&nbsp&nbsp&nbsp由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。
        </p>
        <p>&nbsp&nbsp&nbsp&nbsp此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。
        </p>
        <br>

        <h4>八、本隐私权政策如何更新</h4>
        <p>&nbsp&nbsp&nbsp&nbsp我们可能适时会对本隐私权政策进行调整或变更，本隐私权政策的任何更新将以标注更新时间的方式公布在我们网站上，除法律法规或监管规定另有强制性规定外，经调整或变更的内容一经通知或公布后的7日后生效。如您在隐私权政策调整或变更后继续使用我们提供的任一服务或访问我们相关网站的，我们相信这代表您已充分阅读、理解并接受修改后的隐私权政策并受其约束。
        </p>
        <br>

        <h4>九、如何联系我们</h4>
        <p>&nbsp&nbsp&nbsp&nbsp您可以通过以下方式与我们联系，我们将在30天内回复您的请求：
        </p>
        <p>&nbsp&nbsp&nbsp&nbsp1、如对本政策内容、儿童个人信息有任何疑问、意见或建议，您可通过【潜越】客服邮箱：【race024@163.com】与我们联系。</p>
        <p>&nbsp&nbsp&nbsp&nbsp2、如发现个人信息被泄露，您可以联系我们的客服人员进行投诉举报；</p>
        <p>&nbsp&nbsp&nbsp&nbsp如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。</p>
        <p>&nbsp&nbsp&nbsp&nbsp公司名称：广州锐思云信息科技有限公司</p>
        <p>&nbsp&nbsp&nbsp&nbsp联系邮箱：race024@163.com</p>
    </div>
</template>

<script>
export default {
    name: 'Privacy'
}
</script>

<style scoped></style>