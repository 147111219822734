<template>
  <div>
    <carousel />
    <shop />
    <category />
    <price />
    <show />
    <more />
  </div>
</template>

<script>
import carousel from '@/views/home/components/carousel';
import shop from '@/views/home/components/shop';
import category from '@/views/home/components/category';
import price from '@/views/home/components/price';
import show from '@/views/home/components/show';
import more from '@/views/home/components/more';
export default {
  components: { carousel, shop, category, price, show, more },
};
</script>

<style scoped></style>