<template>
  <div>
    <div class="top">
      <div class="mask">
        <div><img v-lazy="require('@/assets/images/app/applogo.png')" alt="applogo" style="width: 70px;"></div>
        <div class="mask_text">来潜越&nbsp一起玩出精彩生活</div>
        <div><img v-lazy="require('@/assets/images/app/appimg.png')" alt="appimg" style="width: 260px;"></div>
        <div>
          <el-button type="primary" round class="button" @click="down">下载APP</el-button>
        </div>
      </div>
    </div>

    <div>
      <div class="item1">
        <div class="tex1">
          <div class="item1_tx1">正品商城</div>
          <div class="item1_tx2">正品保证假一赔三，覆盖国内外90%的渔具品牌，货品齐全丰富，助力用户一站式购买！</div>
        </div>
        <div><img v-lazy="require('@/assets/images/app/shopimg.png')" alt="shopimg"></div>
      </div>

      <div class="item2">
        <div class="tex2">
          <div class="item2_tx1">价格排名</div>
          <div class="item2_tx2">全网价格排名，一秒知晓全网最具性价比的装备，免去货比三家的烦恼。</div>
        </div>
        <div><img v-lazy="require('@/assets/images/app/priceimg.png')" alt="priceimg"></div>
      </div>

      <div class="item3">
        <div class="tex3">
          <div class="item3_tx1">装备排行榜</div>
          <div class="item3_tx2">全网钓友齐力评选最优质的装备和品牌，解决你选择困难症的烦恼。</div>
        </div>
        <div><img v-lazy="require('@/assets/images/app/zbimg.png')" alt="gjimg"></div>
      </div>

      <div class="item4">
        <div class="tex4">
          <div class="item4_tx1">AI装备搭配</div>
          <div class="item4_tx2">潜越首创，利用大数据处理帮你选出最符合你需求的装备搭配，让你告别新手大礼包，不做韭菜。</div>
        </div>
        <div><img v-lazy="require('@/assets/images/app/aiimg.png')" alt="aiimg"></div>
      </div>

      <div class="item5">
        <div class="tex5">
          <div class="item5_tx1">知识星球</div>
          <div class="item5_tx2">一站式从0到1入门学习，进阶学习，让你从路亚小白变成路亚大神。</div>
        </div>
        <div><img v-lazy="require('@/assets/images/app/zsimg.png')" alt="zsimg"></div>
      </div>

      <div class="item6">
        <div class="item6_tx1">更多功能 敬请期待</div>
        <div class="item6_tx2">鉴定、保险、保养、维修等等....</div>
      </div>
    </div>

    <div class="down">
      <div class="num">
        <div style="margin-top: 30px;">路亚</div>
        <div>骑行</div>
        <div>滑雪</div>
        <div>露营</div>
        <div>冲浪</div>
        <div style="margin-bottom: 30px;">潜水</div>
      </div>
    </div>

    <div class="di">
      <div><img v-lazy="require('@/assets/images/app/gz.png')" alt="gzlogo"></div>
      <div class="hz">商家合作&nbsp&nbsp&nbsp&nbsp|&nbsp&nbsp&nbsp&nbsp融资对接&nbsp&nbsp&nbsp&nbsp|&nbsp&nbsp&nbsp&nbsp
        <a href="https://kdocs.cn/l/cui9D1MmT4WG" target="_blank" style="text-decoration: none;">隐私政策</a>
      </div>
      <div class="gs">广州锐思云信息科技有限公司</div>
      <div class="bei"><a href="http://beian.miit.gov.cn/" target="_blank"
          style="text-decoration: none;">粤ICP备2023063105号</a></div>
    </div>

    <div class="wx" v-if="weixin" @click="weixin = false"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      weixin: false, //是否为微信内浏览器打开
    };
  },
  methods: {
    downTest() {
      window.location.href = "https://apps.apple.com/app/id6462408062";
    },
    down() {
      const u = navigator.userAgent.toLowerCase();
      const isWeixin = u.includes('micromessenger'); // 检测是否在微信内浏览器中打开
      const isAndroid = u.includes('android'); // 检测是否为Android终端
      const isIOS = /(iphone|ipad|ipod|ios)/i.test(u); // 检测是否为iOS终端
      const isPc = u.includes('windows'); // 检测是否为PC终端
      const isMacPc = u.includes('macintosh'); // 检测是否为MacPC终端

      // 微信内浏览器
      if (isWeixin) {
        this.weixin = true;
      } else if (isAndroid) {
        // Android终端
        if (u.includes('vivo')) {
          window.location.href = "https://h5coml.vivo.com.cn/h5coml/appdetail_h5/browser_v2/index.html?appId=3576115&resource=301&source=7";
          return;
        } else if (u.includes('xiaomi')) {
          window.location.href = "https://app.mi.com/details?id=com.ruisi.mall&ref=search";
          return;
        } else if (u.includes('huawei')) {
          window.location.href = "https://url.cloud.huawei.com/p5UXIhC0og";
          return;
        } else {
          window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.ruisi.mall";
          return;
        }
      } else if (isIOS) {
        // iOS终端
        setTimeout(() => {
          if (!document.hidden) {
            this.downTest();
          }
        }, 500);
        return;
      } else if (isPc) {
        // PC终端
        window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.ruisi.mall';
        return;
      } else if (isMacPc) {
        // MacPC终端
        this.downTest();
        return;
      } else {
        // 其他设备类型，可以给出提示或引导用户进行下载
        alert('请在支持的设备上打开下载');
      }
    }
  }
};
</script>

<style scoped>
.top {
  width: 100%;
  height: 757px;
  z-index: -1;
  background-image: url('@/assets/images/app/himg.png');
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
}

.mask {
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.5);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mask_text {
  color: #fff;
  font-size: 30px;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

.button {
  width: 320px;
  height: 50px;
  background-color: black;
  border-color: #fff;
  border-radius: 16px;
  margin-top: 35px;
  font-size: 20px;
  font-family: "Arial", sans-serif;
}

.dia {
  margin-top: 150px;
}

.dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button-container span {
  font-size: 10px;
  color: #686868;
}

.btn {
  width: 150px;
  background-color: #8000a30a;
  border-color: #8100A3;
  color: #8100A3;
  border-radius: 10px;
}

.item1 {
  width: 100%;
  height: 550px;
  z-index: -1;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tex1 {
  margin-left: 25px;
}

.item1_tx1 {
  color: #8100A3;
  font-size: 30px;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
}

.item1_tx2 {
  color: #373737;
  font-size: 15px;
  font-family: "Arial", sans-serif;
}

.item1 img {
  height: 320px;
  margin-right: 25px;
}

.item2 {
  width: 100%;
  height: 550px;
  z-index: -1;
  background-color: #F2F2F2;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tex2 {
  margin-left: 25px;
}

.item2_tx1 {
  color: #8100A3;
  font-size: 30px;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
}

.item2_tx2 {
  color: #373737;
  font-size: 15px;
  font-family: "Arial", sans-serif;
}

.item2 img {
  height: 320px;
  margin-right: 25px;
}

.item3 {
  width: 100%;
  height: 550px;
  z-index: -1;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tex3 {
  margin-left: 25px;
}

.item3_tx1 {
  color: #8100A3;
  font-size: 30px;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
}

.item3_tx2 {
  color: #373737;
  font-size: 15px;
  font-family: "Arial", sans-serif;
}

.item3 img {
  height: 320px;
  margin-right: 25px;
}

.item4 {
  width: 100%;
  height: 550px;
  z-index: -1;
  background-color: #F2F2F2;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tex4 {
  margin-left: 25px;
}

.item4_tx1 {
  color: #8100A3;
  font-size: 30px;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
}

.item4_tx2 {
  color: #373737;
  font-size: 15px;
  font-family: "Arial", sans-serif;
}

.item4 img {
  height: 320px;
  margin-right: 25px;
}

.item5 {
  width: 100%;
  height: 550px;
  z-index: -1;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tex5 {
  margin-left: 25px;
}

.item5_tx1 {
  color: #8100A3;
  font-size: 30px;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
}

.item5_tx2 {
  color: #373737;
  font-size: 15px;
  font-family: "Arial", sans-serif;
}

.item5 img {
  height: 320px;
  margin-right: 25px;
}

.item6 {
  width: 100%;
  height: 200px;
  z-index: -1;
  background-color: #F2F2F2;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.item6_tx1 {
  color: #8100A3;
  font-size: 30px;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  margin-bottom: 25px;
}

.item6_tx2 {
  color: #9A9A9A;
  font-size: 20px;
  font-family: "Arial", sans-serif;
}

.down {
  width: 100%;
  height: 757px;
  z-index: -1;
  background-image: url('@/assets/images/app/himg.png');
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
}

.num {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  color: #fff;
  font-size: 30px;
  font-family: "Arial", sans-serif;
}

.di {
  width: 100%;
  height: 285px;
  z-index: -1;
  background-color: #3A3A3A;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.di img {
  width: 80px;
  margin-bottom: 25px;
}

.hz {
  color: #fff;
  font-size: 15px;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
}

.hz a {
  color: #fff;
  font-size: 15px;
  font-family: "Arial", sans-serif;
}

.gs {
  color: #fff;
  font-size: 10px;
  font-family: "Arial", sans-serif;
}

.bei a {
  color: #fff;
  font-size: 10px;
  font-family: "Arial", sans-serif;
}

.wx {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  background-color: rgba(0, 0, 0, .7);
  width: 100vw;
  height: 100vh;
  background-image: url('@/assets/images/app/wx.png');
  background-size: 169px 150px;
  background-position: right 30px top 10px;
  background-repeat: no-repeat;
}
</style>