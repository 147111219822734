<template>
  <div class="con4">
    <div class="con_text">
      <div class="text1">社区</div>
      <div class="text2">Show出你的生活，找到志同道合的伙伴，发现更多垂钓知识。</div>
    </div>
    <div>
      <img v-lazy="require('@/assets/images/pc/show1.png')" alt="appshop" style="margin-right: 20px;">
      <img v-lazy="require('@/assets/images/pc/show2.png')" alt="appshop" style="margin-right: 20px;">
      <img v-lazy="require('@/assets/images/pc/show3.png')" alt="appshop">
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.con4 {
  width: 100%;
  height: 740px;
  background-color: #EFEFEF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.con4 img {
  height: 580px;
}

.con_text {
  margin-right: 40px;
}

.text1 {
  font-family: "Arial", sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: #8100A3;
}

.text2 {
  font-family: "Arial", sans-serif;
  font-size: 20px;
  color: #373737;
  margin-top: 35px;
}
</style>