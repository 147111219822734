<template>
    <div class="con3">
        <div>
            <img v-lazy="require('@/assets/images/pc/detail1.png')" alt="appshop" style="margin-right: 20px;">
            <img v-lazy="require('@/assets/images/pc/detail2.png')"  alt="appshop" style="margin-right: 20px;">
            <img v-lazy="require('@/assets/images/pc/detail3.png')"  alt="appshop">
        </div>
        <div class="con_text">
            <div class="text1">价格排名</div>
            <div class="text2">省去用户货比三家的烦恼，价格全网排名展示，一秒知晓全网最具性价比的价格！</div>
        </div>
    </div>
</template>
  
<script>
export default {

}
</script>
  
<style scoped>
.con3 {
    width: 100%;
    height: 740px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.con3 img {
    height: 580px;
}

.con_text {
    margin-left: 40px;
}

.text1 {
    font-family: "Arial", sans-serif;
    font-size: 40px;
    font-weight: bold;
    color: #8100A3;
}

.text2 {
    font-family: "Arial", sans-serif;
    font-size: 20px;
    color: #373737;
    margin-top: 35px;
}
</style>